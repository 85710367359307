@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-quill .ql-toolbar {
  @apply rounded-t-lg dark:border-gray-dark;
}

.custom-quill .ql-container {
  @apply border border-gray-100  dark:border-gray-dark rounded-b-lg;
}

.custom-quill .ql-editor {
  @apply h-32;
}

.custom-quill .ql-toolbar button {
  @apply bg-blue-500 text-white;
}
